import React from "react";
import { useDispatch } from "react-redux";
//actions
import { createEmployee } from "components/appStore";
//imports

//ui style
import {
  Classes,
  Button,
  Dialog,
  Intent,
  FormGroup,
  InputGroup,
  Callout,
  Switch,
  NumericInput,
} from "@blueprintjs/core";

function CreateEmployee({ i, isOpen, handleClose }) {
  const dispatch = useDispatch();
  const [name, setName] = React.useState("New Employee");
  const [isBatch, setIsBatch] = React.useState(false);
  const [batchCount, setBatchCount] = React.useState(2);

  const handleSubmit = () => {
    dispatch(
      createEmployee({
        id: i,
        name: name,
        isBatch: isBatch,
        batchCount: batchCount,
      })
    );
    handleClose();
  };

  return (
    <Dialog
      icon="info-sign"
      onClose={handleClose}
      isOpen={isOpen}
      title="Create new employee"
      style={{ width: "75%" }}
    >
      <div className={Classes.DIALOG_BODY}>
        <Callout intent="primary">
          You can either create 1 employee or create a batch of employees to
          save time.
        </Callout>
        <br />
        <FormGroup
          helperText="Enter a name for this employee. If this is a batch then use a descriptive name to keep track for yourself"
          label="Employee/batch name"
          labelFor="text-input"
        >
          <InputGroup
            id="text-input"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="New Entity"
          />
        </FormGroup>
        <FormGroup
          helperText="Use this option to enter a batch of employees all at once"
          label="Use batch mode?"
          labelFor="batch-input"
        >
          <Switch
            checked={isBatch}
            innerLabel="No"
            innerLabelChecked="Yes"
            onChange={(e) => setIsBatch(e.currentTarget.checked)}
          />
        </FormGroup>
        {isBatch ? (
          <div>
            <Callout intent="warning">
              <p>
                Batch mode should only be used if{" "}
                <b>one of the following is true</b> for the batch of employees:
              </p>
              <ul>
                <li>
                  All members of this batch are arm length parties (not related)
                  and have earned <b>$1,129.33 or more</b> for each week of the
                  claim period
                </li>
                <li>
                  All members of this batch are arm length parties and have
                  earned <b>less than $1,129.33</b> for each week of the claim
                  period <b>AND</b> there has been no reduction in pay
                </li>
                <li>
                  All members of this batch are <b>non-arm length parties</b>{" "}
                  (related) and have earned <b>$1,129.33 or more</b> for each
                  week of the claim period <b>AND</b> have earned an average of
                  $1,129.33 per week or more between January 1, 2020 and March
                  15, 2020
                </li>
              </ul>
            </Callout>
            <FormGroup
              helperText="How many employees in this batch"
              label="Batch size"
              labelFor="batch-input"
              labelInfo="(required)"
            >
              <NumericInput
                disabled={!isBatch}
                min={2}
                minorStepSize={1}
                onValueChange={(v) => setBatchCount(v)}
                value={batchCount}
              />
            </FormGroup>
          </div>
        ) : null}
        <br />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button intent={Intent.PRIMARY} onClick={handleSubmit}>
            Create
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default CreateEmployee;
