import React from "react";
import { render } from "react-dom";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import App from "./App";
import rootReducer from "reducers";

import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "flexboxgrid/css/flexboxgrid.min.css";
import "./index.css";

const store = configureStore({
  reducer: rootReducer,
});

if (process.env.NODE_ENV === "production") {
  console.log = function () {};
}

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
