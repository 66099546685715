import React from "react";
import { useDispatch, useSelector } from "react-redux";
//actions
import {
  save,
  saveas,
  load,
  setLicense,
  setSelected,
} from "components/appStore";
//imports
import CreateEntity from "components/CreateEntity";
import EntityView from "components/EntityView";
import LoadFile from "./LoadFile";
import Help from "./Help";
import About from "./About";
//ui style
import {
  Button,
  ButtonGroup,
  Tree,
  Callout,
  H5,
  H2,
  Intent,
  Navbar,
  NavbarGroup,
  Alignment,
  Tooltip,
} from "@blueprintjs/core";

function View() {
  const dispatch = useDispatch();
  const { saved, loaded, entities, license, selected } = useSelector(
    (state) => ({
      saved: state.app.saved,
      loaded: state.app.loaded,
      entities: state.app.entity,
      license: state.app.license,
      selected: state.app.selected,
    })
  );
  const [isOpen, setIsOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState([]);
  const [isLoadOpen, setIsLoadOpen] = React.useState(false);
  //other stuff
  const [isHelpOpen, setIsHelpOpen] = React.useState(false);
  const [isAboutOpen, setIsAboutOpen] = React.useState(false);

  React.useEffect(() => {
    dispatch(load());
  }, []);

  //create entity

  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);
  //tree controls

  const handleNodeClick = (node) => {
    dispatch(setSelected(node.id));
  };

  const handleNodeExpand = (node) => {
    var r = expanded.slice();
    r.push(node.id);
    console.log(r);
    setExpanded(r);
  };
  const handleNodeCollapse = (node) => {
    var r = expanded.filter((e) => {
      return e !== node.id;
    });
    console.log(r);
    setExpanded(r);
  };
  //saving

  const handleSave = () => {
    dispatch(save());
  };
  const handleExport = () => {
    dispatch(saveas());
  };
  const handleOpenLoadFile = () => {
    setIsLoadOpen(true);
  };
  const handleCloseLoadFile = () => {
    setIsLoadOpen(false);
  };

  if (!loaded) {
    return <div>Loading saved data from your computer</div>;
  }

  if (license !== true) {
    return (
      <div>
        <H2>Usage agreement:</H2>
        <p>
          WHILE WE HAVE MADE EVERY REASONABLE EFFORT TO ENSURE THE ACCURACY OF
          THIS SOFTWARE, YOU AGREE THAT THE SOFTWARE IS PROVIDED "AS IS",
          WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT
          LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS BE LIABLE
          FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF
          CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION
          WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.{" "}
        </p>
        <Button
          onClick={() => dispatch(setLicense(true))}
          intent={Intent.PRIMARY}
        >
          I AGREE - CONTINUE
        </Button>
      </div>
    );
  }

  //build tree for left side navigation
  var treeNodes = entities.map((e, i) => {
    var isExpanded = expanded.includes(i);
    return {
      id: i,
      hasCaret: false,
      icon: "office",
      label: e.name,
      isExpanded: isExpanded,
      isSelected: i === selected,
    };
  });

  return (
    <div>
      <Navbar className="no-print">
        <NavbarGroup>
          <img
            src="logo.png"
            alt="logo"
            style={{ width: "auto", height: "80%" }}
          />
        </NavbarGroup>
        <NavbarGroup align={Alignment.RIGHT}>
          <ButtonGroup>
            <Tooltip content="Create a new entity">
              <Button minimal fill icon="add" onClick={handleOpen} text="New" />
            </Tooltip>
            <Tooltip content="Save to browser local storage (see About for more info)">
              <Button
                minimal
                fill
                icon="floppy-disk"
                onClick={handleSave}
                text="Save"
              />
            </Tooltip>
            <Tooltip content="Export to file">
              <Button
                minimal
                fill
                icon="export"
                onClick={handleExport}
                text="Export"
              />
            </Tooltip>
            <Tooltip content="Import from a file">
              <Button
                minimal
                fill
                icon="import"
                onClick={handleOpenLoadFile}
                text="Import"
              />
            </Tooltip>
            <Button
              minimal
              fill
              icon="help"
              onClick={() => setIsHelpOpen(true)}
              text="Help"
            />
            <Button
              minimal
              fill
              icon="info-sign"
              onClick={() => setIsAboutOpen(true)}
              text="About"
            />
          </ButtonGroup>
        </NavbarGroup>
      </Navbar>
      {true ? (
        <Callout intent="danger" style={{ marginTop: "10px" }}>
          This calculator currently only works for the first three period of
          CEWS. CRA has published their own up to date calculator which should
          be used for periods after June 6, 2020. You can find their calculator{" "}
          <a href="https://www.canada.ca/en/revenue-agency/services/subsidy/emergency-wage-subsidy/cews-calculate-subsidy-amount.html">
            {" "}
            here
          </a>
          <br />
          This calculator is maintained for archival purposes only.
        </Callout>
      ) : null}
      {saved ? null : (
        <div>
          <Callout intent="warning" style={{ marginTop: "10px" }}>
            You have unsaved changes
          </Callout>
        </div>
      )}
      <br />
      {selected < 0 ? (
        <div style={{ width: "100%", textAlign: "center" }}>
          {entities.length > 0 ? (
            <H5>Start by selecting an entity from the left or</H5>
          ) : null}
          <Button onClick={handleOpen} intent={Intent.PRIMARY}>
            Click here to create new entity
          </Button>
        </div>
      ) : null}
      <div className="row start-md">
        <div className="col-sm-4 col-md-3 col-lg-2 no-print">
          <H5>Entities:</H5>

          <Tree
            contents={treeNodes}
            onNodeExpand={handleNodeExpand}
            onNodeCollapse={handleNodeCollapse}
            onNodeClick={handleNodeClick}
          />
        </div>
        <div className="col-sm-8 col-md-9 col-lg-10 print-full">
          {selected >= 0 ? <EntityView entityID={selected} /> : null}
        </div>
      </div>
      <CreateEntity isOpen={isOpen} handleClose={handleClose} />
      <LoadFile isOpen={isLoadOpen} handleClose={handleCloseLoadFile} />
      <Help isOpen={isHelpOpen} handleClose={() => setIsHelpOpen(false)} />
      <About isOpen={isAboutOpen} handleClose={() => setIsAboutOpen(false)} />
    </div>
  );
}

export default View;
