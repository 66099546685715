import { createAction, createReducer } from "@reduxjs/toolkit";
import download from "downloadjs";
import moment from "moment";
import { Position, Toaster } from "@blueprintjs/core";

//toaster class
const AppToaster = Toaster.create({
  className: "app-notification-toaster",
  position: Position.BOTTOM,
});

//thunks
export function save() {
  return function (dispatch, getState) {
    dispatch(setSaved(true));
    var state = getState();
    console.log(state.app);
    localStorage.setItem("app", JSON.stringify(state.app));
    AppToaster.show({
      message: "Data saved successfully",
      intent: "success",
    });
  };
}

export function saveas() {
  return function (dispatch, getState) {
    dispatch(setSaved(true));
    var state = getState();
    var filename =
      "polaris-cews-" + moment().format("YYYY-MM-DD-HH-mm-ssZ") + ".json";
    download(JSON.stringify(state.app), filename, "application/json");
  };
}

export function load() {
  return function (dispatch, getState) {
    const loadState = () => {
      try {
        const serializedState = localStorage.getItem("app");
        if (serializedState === null) {
          return undefined;
        }
        return JSON.parse(serializedState);
      } catch (err) {
        return undefined;
      }
    };

    var state = loadState();
    console.log("reading from localStorage: ", state);
    if (state) {
      //load state
      dispatch(setAppState(state));
    }
    dispatch(setLoaded(true));
  };
}

export function loadFromFile(file) {
  return function (dispatch, getState) {
    let reader = new FileReader();
    reader.readAsText(file);
    reader.onload = function () {
      var result = reader.result;
      console.log(result);
      var state = undefined;
      try {
        state = JSON.parse(result);
      } catch (err) {
        AppToaster.show({
          message: "Error reading from file",
          intent: "danger",
        });
        console.log(err);
      }

      if (state) {
        dispatch(setAppState(state));
        AppToaster.show({
          message: "File loaded successfully",
          intent: "success",
        });
      }
    };
    reader.onerror = function () {
      //we should notify the user of error here somehow? toaster?
      console.log(reader.error);
    };
  };
}

//actions
export const setSaved = createAction("app/set/saved");
export const setLoaded = createAction("app/set/loaded");
export const setAppState = createAction("app/set/appState");
export const createEntity = createAction("app/create/entity");
export const deleteEntity = createAction("app/delete/entity");
export const cloneEntity = createAction("app/clone/entity");
export const createEmployee = createAction("app/create/employee");
export const setEntityValues = createAction("app/set/entity/values");
export const setEmployee = createAction("app/set/employee");
export const setLicense = createAction("app/set/license");
export const setSelected = createAction("app/set/selected");

// defaults
const initialState = {
  entity: [],
  saved: true,
  loaded: false,
  license: false,
  selected: -1,
};

const newEntity = (name) => {
  return {
    name: name,
    period: 3,
    employee: [],
  };
};

//reducer

export const appReducer = createReducer(initialState, {
  [setSaved]: (state, action) => {
    state.saved = action.payload;
  },
  [setLoaded]: (state, action) => {
    state.loaded = action.payload;
  },
  [setAppState]: (state, action) => {
    console.log(action.payload);
    return action.payload;
  },
  [setLicense]: (state, action) => {
    state.license = action.payload;
  },
  [setSelected]: (state, action) => {
    state.selected = action.payload;
    if (state.selected >= state.entity.length) {
      state.selected = -1;
    }
  },
  [createEntity]: (state, action) => {
    state.entity.push(newEntity(action.payload));
    state.saved = false;
    state.selected = state.entity.length - 1;
  },
  [deleteEntity]: (state, action) => {
    const { row } = action.payload;
    console.log(row);
    if (row < state.entity.length && row >= 0) {
      console.log("deleting row ", row);
      state.entity.splice(row, 1);
      state.saved = false;
      state.selected = -1;
    }
  },
  [cloneEntity]: (state, action) => {
    const { row } = action.payload;
    if (row < state.entity.length && row >= 0) {
      console.log("deleting row ", row);
      var next = JSON.parse(JSON.stringify(state.entity[row]));
      next.name = "Copy of " + next.name;
      state.entity.push(next);
      state.selected = state.entity.length - 1;
      state.saved = false;
    }
  },
  [createEmployee]: (state, action) => {
    const { id } = action.payload;
    if (id < state.entity.length && id >= 0) {
      state.entity[id].employee.push(newEmp(action.payload));
      state.saved = false;
    }
  },
  [setEntityValues]: (state, action) => {
    const { id, target, value } = action.payload;
    if (id < state.entity.length && id >= 0) {
      if (target in state.entity[id]) {
        state.entity[id][target] = value;
        state.saved = false;
      }
    }
  },
  [setEmployee]: (state, action) => {
    const { id, emp, value } = action.payload;
    if (id < state.entity.length && id >= 0) {
      if (emp < state.entity[id].employee.length && emp >= 0) {
        state.entity[id].employee[emp] = value;
        state.saved = false;
      }
    }
  },
});

//employee defaults
const newEmp = ({ name, isBatch, batchCount }) => {
  return {
    name: name,
    isBatch: isBatch,
    batchCount: batchCount,
    isRelated: false,
    //this is the calculation mode:
    // total - total wage -> prorate by number of weeks
    // custom - weekly -> enter the pay for each week
    mode: "total",
    //used only for "total" mode
    total: "0.00", //avg wage => total / (75 days / 7)
    //used only for custom blocks
    custom: [
      /**
       * {
       *  start: {y, M, d},
       *  end: {y, M, d},
       *  amount: 1234,
       *  invalidStart: false,
       *  invalidEnd: false,
       * }
       */
    ],
    baseline: "0.00",
    //total amount paid during the qualifying period
    paid: ["0", "0", "0", "0"],
    subsidy: ["0", "0", "0", "0"],
    totalPaid: "0.00",
    totalSubsidy: "0.00",
    hasError: false,
  };
};
