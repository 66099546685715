import React from "react";

import View from "components/View";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

function App() {
  const { width } = useWindowDimensions();
  if (width < 800) {
    return (
      <div>
        Sorry. Our application is not optimized for mobile and small screens.
        Please view this on a larger screen.
      </div>
    );
  }
  return (
    <div style={{ textAlign: "center" }}>
      <View />
    </div>
  );
}

export default App;
