import React from "react";
import { useDispatch } from "react-redux";
import { loadFromFile } from "./appStore";
import {
  Button,
  Classes,
  Dialog,
  FileInput,
  Intent,
  Callout,
} from "@blueprintjs/core";

function LoadFile({ isOpen, handleClose }) {
  const [text, setText] = React.useState("Choose a file...");
  const [selected, setSelected] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const dispatch = useDispatch();

  return (
    <Dialog
      icon="document-open"
      onClose={handleClose}
      isOpen={isOpen}
      title="Load state from file"
    >
      <div className={Classes.DIALOG_BODY}>
        <Callout intent="warning">
          Please only upload an unmodified JSON created by this app. Any other
          file or modified output may cause unexpected behaviors.
        </Callout>
        <br />
        <FileInput
          fill
          text={text}
          inputProps={{ accept: "application/json", multiple: false }}
          onInputChange={(e) => {
            console.log(e);
            console.log(e.target.files);
            if (e.target.files.length === 1) {
              setText(e.target.files[0].name);
              setSelected(true);
              setFile(e.target.files[0]);
            } else {
              setSelected(false);
              setFile(null);
            }
          }}
        />
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            intent={Intent.PRIMARY}
            disabled={!selected}
            onClick={() => {
              //do stuff here?
              console.log("hi");
              console.log(file);
              dispatch(loadFromFile(file));

              handleClose();
            }}
          >
            Load
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default LoadFile;
