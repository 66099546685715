import React from "react";
import { Button, Classes, Drawer, H3, Position } from "@blueprintjs/core";

function About({ isOpen, handleClose }) {
  return (
    <Drawer
      icon="info-sign"
      onClose={handleClose}
      isOpen={isOpen}
      title="About"
      position={Position.LEFT}
    >
      <div className={Classes.DIALOG_BODY}>
        <H3>Disclaimer</H3>
        <p>
          WHILE WE HAVE MADE EVERY REASONABLE EFFORT TO ENSURE THE ACCURACY OF
          THIS SOFTWARE, YOU AGREE THAT THE SOFTWARE IS PROVIDED "AS IS",
          WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT
          LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS BE LIABLE
          FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF
          CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION
          WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.{" "}
        </p>
        <H3>Saving and security</H3>
        <p>
          This application operates purely on your local computer (after it is
          loaded). When you click save, your data is saved to your browser's
          local storage. Nothing is ever sent across the internet.
        </p>
        <p>
          Because we utilize your browser's local storage, this application is
          as secure (or unsecure) as your local computer. It also means that if
          you were to change browser/computer, any saved data will not be
          viewable. You do have the option of exporting out the data into a JSON
          file and then reloading it back in. You should use this option if you
          plan on using different computers or just for the purpose of keeping a
          copy for yourself. Do note however that if you make any modifications
          to the JSON file outside of the app, there is not guarantee that you
          can load it back in and expect it to work.
        </p>
        <p>
          Make sure however that you are visiting this webapp on our website at
          https://cews.polariscpa.ca. If you are viewing this from an untrusted
          source, there is no guarantee that the webapp has not been modified in
          a malicious way.
        </p>
      </div>

      <div className={Classes.DRAWER_FOOTER}>
        <Button fill onClick={handleClose}>
          Close
        </Button>
      </div>
    </Drawer>
  );
}

export default About;
