import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Big from "big.js";
//actions
import {
  cloneEntity,
  setEntityValues,
  deleteEntity,
} from "components/appStore";
//imports
import EditEmployee from "./EditEmployee";
import CreateEmployee from "./CreateEmployee";
//ui style
import {
  Classes,
  Intent,
  Elevation,
  EditableText,
  FormGroup,
  H3,
  Tooltip,
  Card,
  Button,
  H5,
  Collapse,
  HTMLSelect,
  HTMLTable,
  ButtonGroup,
  Icon,
  Popover,
  Colors,
} from "@blueprintjs/core";

var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "CAD",
});

function EntityView({ entityID }) {
  const dispatch = useDispatch();
  const { entity } = useSelector((state) => ({
    entity: state.app.entity[entityID],
  }));

  const [name, setName] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(["info", "pay", "result"]);
  const [editOpen, setEditOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(-1);
  const [createOpen, setCreateOpen] = React.useState(false);

  //effects
  React.useEffect(() => {
    setName(entity.name);
    setIsOpen(["info", "pay", "result"]);
  }, [entityID, entity]);

  //handlers
  const handleSetName = () => {
    dispatch(setEntityValues({ id: entityID, target: "name", value: name }));
  };
  const handleSetPeriod = (val) => {
    dispatch(setEntityValues({ id: entityID, target: "period", value: val }));
  };

  const handleToggle = (id) => {
    //if id already exists remove
    var r = [];
    if (isOpen.includes(id)) {
      r = isOpen.filter((e) => {
        return e !== id;
      });
    }
    //else add
    else {
      r = isOpen.slice();
      r.push(id);
    }
    setIsOpen(r);
  };

  const handleEditEmployee = (i) => {
    setSelected(i);
    setEditOpen(true);
  };

  const handleCreateEmployee = () => {
    setCreateOpen(true);
  };

  var totalEmployeeCount = 0;
  var totalRemuneration = Big(0);
  var totalSubsidy = Big(0);

  const rows = entity.employee.map((x, i) => {
    if (!x.hasError) {
      totalEmployeeCount += x.isBatch ? x.batchCount : 1;
      totalRemuneration = totalRemuneration.add(Big(x.totalPaid));
      totalSubsidy = totalSubsidy.add(Big(x.totalSubsidy));
    }
    return (
      <tr key={i}>
        <td>
          {x.hasError ? (
            <Tooltip
              content="There is an error with this employee. No subsidy has been calculated for this employee"
              intent="danger"
            >
              <Icon icon="warning-sign" intent="danger" />
            </Tooltip>
          ) : null}
        </td>
        <td>
          {x.isBatch ? (
            <span>
              <b>[batch] </b>
              {x.name}
            </span>
          ) : (
            x.name
          )}
        </td>
        <td>{x.isRelated ? "Yes" : "No"}</td>
        <td>{x.isBatch ? x.batchCount : 1}</td>
        <td>{x.baseline}</td>
        <td>{x.totalPaid}</td>
        <td>{x.totalSubsidy}</td>
        <td>
          <Button
            minimal
            small
            icon="edit"
            onClick={() => handleEditEmployee(i)}
          />
        </td>
      </tr>
    );
  });

  if (entity.employee.length === 0) {
    rows.push(
      <tr key="none">
        <td colSpan={8}>No employees. Start by adding some.</td>
      </tr>
    );
  }

  return (
    <div>
      <Tooltip content="Click on name to edit">
        <H3>
          <EditableText
            value={name}
            onChange={(v) => setName(v)}
            onCancel={(v) => setName(v)}
            onConfirm={handleSetName}
          />
        </H3>
      </Tooltip>
      <br />
      <H5>
        <span style={{ color: Colors.BLUE1 }}>Step 1: General Info</span>{" "}
        <Button
          minimal
          onClick={() => handleToggle("info")}
          className="no-print"
          icon={isOpen.includes("info") ? "chevron-down" : "chevron-left"}
        />
      </H5>
      <Collapse isOpen={isOpen.includes("info")}>
        <Card
          elevation={Elevation.TWO}
          style={{ marginTop: "1em", marginBottom: "1em" }}
        >
          <FormGroup
            helperText="Select the period covered by this claim. This field is used to populate the employee wages chart."
            label="Period covered by this claim"
            labelFor="select-period"
            labelInfo="(required)"
          >
            <HTMLSelect
              id="select-period"
              fill
              options={[
                { label: "March 15 to April 11, 2020", value: 3 },
                { label: "April 12 to May 9, 2020", value: 4 },
                { label: "May 10 to June 6, 2020", value: 5 },
              ]}
              value={entity.period}
              onChange={(e) => {
                handleSetPeriod(parseInt(e.currentTarget.value));
              }}
            />
          </FormGroup>
        </Card>
      </Collapse>
      <H5>
        <span style={{ color: Colors.BLUE1 }}>
          Step 2: Employee Remuneration
        </span>{" "}
        <Button
          minimal
          onClick={() => handleToggle("pay")}
          className="no-print"
          icon={isOpen.includes("pay") ? "chevron-down" : "chevron-left"}
        />
      </H5>
      <Collapse isOpen={isOpen.includes("pay")}>
        <Card
          elevation={Elevation.TWO}
          style={{ marginTop: "1em", marginBottom: "1em" }}
        >
          <p>
            The table below summarizes the remuneration for each employee (or
            batches of employee)
          </p>

          <HTMLTable condensed striped interactive style={{ width: "100%" }}>
            <thead>
              <tr>
                <td></td>
                <td>
                  <b>Employee</b>
                </td>
                <td>
                  <b>Related Party</b>
                </td>
                <td>
                  <b>Count</b>
                </td>
                <td>
                  <Tooltip content="This is the baseline pay calculated to be the average weekly pay between January 1, 2020 and March 15, 2020">
                    <b>Baseline Pay/Week</b>
                  </Tooltip>
                </td>
                <td>
                  <b>Claim Period Total Pay</b>
                </td>
                <td>
                  <b>Subsidy</b>
                </td>
                <td></td>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
            <tfoot>
              <tr>
                <td></td>
                <td>
                  <b>Total</b>
                </td>
                <td></td>
                <td>
                  <b>{totalEmployeeCount}</b>
                </td>
                <td></td>
                <td>
                  <b>{totalRemuneration.toFixed(2)}</b>
                </td>
                <td>
                  <b>{totalSubsidy.toFixed(2)}</b>
                </td>
                <td></td>
              </tr>
            </tfoot>
          </HTMLTable>
          <br />
          <Button
            icon="add"
            fill
            onClick={handleCreateEmployee}
            intent="primary"
            className="no-print"
          >
            Add Employee
          </Button>
        </Card>
      </Collapse>
      <H5>
        <span style={{ color: Colors.BLUE1 }}>Results</span>{" "}
        <Button
          minimal
          onClick={() => handleToggle("result")}
          className="no-print"
          icon={isOpen.includes("result") ? "chevron-down" : "chevron-left"}
        />
      </H5>
      <Collapse isOpen={isOpen.includes("result")}>
        <Card
          elevation={Elevation.TWO}
          style={{ marginTop: "1em", marginBottom: "1em" }}
        >
          <H5>
            Please enter the following information in your CEWS application:
          </H5>
          Step 2 Line A - Number of eligible employees:{" "}
          <b>{totalEmployeeCount}</b>
          <br />
          Step 2 Line B - Total eligible renumeration paid:{" "}
          <b>
            {formatter.format(totalRemuneration.toFixed(2)).replace("CA$", "$")}
          </b>
          <br />
          Step 2 Line C - Basic Canada Emergency Wage Subisidy:{" "}
          <b>{formatter.format(totalSubsidy.toFixed(2)).replace("CA$", "$")}</b>
        </Card>
      </Collapse>
      <br />
      <ButtonGroup fill className="no-print">
        <Button
          onClick={() => {
            dispatch(cloneEntity({ row: entityID }));
          }}
        >
          Make a Copy
        </Button>
        <Popover
          content={
            <Card>
              <H5>Confirm deletion</H5>
              <p>Are you sure you want to delete this entity?</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 15,
                }}
              >
                <ButtonGroup fill>
                  <Button
                    className={Classes.POPOVER_DISMISS}
                    style={{ marginRight: 10 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    intent={Intent.DANGER}
                    className={Classes.POPOVER_DISMISS}
                    onClick={() => {
                      dispatch(deleteEntity({ row: entityID }));
                    }}
                  >
                    Delete
                  </Button>
                </ButtonGroup>
              </div>
            </Card>
          }
        >
          <Button intent="danger">Delete</Button>
        </Popover>
      </ButtonGroup>
      <br />
      <br />
      <CreateEmployee
        i={entityID}
        isOpen={createOpen}
        handleClose={() => {
          setCreateOpen(false);
        }}
      />
      {entity.employee[selected] ? (
        <EditEmployee
          entityID={entityID}
          employeeID={selected}
          isOpen={editOpen}
          handleClose={() => {
            setEditOpen(false);
            setSelected(-1);
          }}
        />
      ) : null}
    </div>
  );
}

export default EntityView;
