import React from "react";
import { useDispatch } from "react-redux";
//actions
import { createEntity } from "components/appStore";
//imports

//ui style
import {
  Classes,
  Button,
  Dialog,
  Tooltip,
  Intent,
  FormGroup,
  InputGroup,
} from "@blueprintjs/core";

function CreateEntity({ isOpen, handleClose }) {
  const dispatch = useDispatch();
  const [name, setName] = React.useState("New Entity");

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createEntity(name));
    handleClose();
  };

  return (
    <Dialog
      icon="info-sign"
      onClose={handleClose}
      isOpen={isOpen}
      title="Create new entity"
    >
      <form onSubmit={handleSubmit}>
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            helperText="Enter a name for this entity. This is for your information only."
            label="Entity name"
            labelFor="text-input"
            labelInfo="(required)"
          >
            <InputGroup
              id="text-input"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="New Entity"
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Tooltip content="This button is hooked up to close the dialog.">
              <Button onClick={handleClose}>Cancel</Button>
            </Tooltip>
            <Button intent={Intent.PRIMARY} onClick={handleSubmit}>
              Create
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
}

export default CreateEntity;
