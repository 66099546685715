import React from "react";
import { Button, Classes, Drawer, Position, H3 } from "@blueprintjs/core";

function Help({ isOpen, handleClose }) {
  return (
    <Drawer
      icon="help"
      onClose={handleClose}
      isOpen={isOpen}
      title="Instructions"
      position={Position.LEFT}
    >
      <div className={Classes.DIALOG_BODY}>
        <H3>Basics</H3>
        <ol>
          <li>
            Start by creating an entity. You may create as many entities as you
            wish.
          </li>
          <li>
            Select the entity you have created from the entities list on the
            left. Complete the the required two steps for each entity
          </li>
          <li>
            Under Step 2, add every employee that is eligible for a claim by
            clicking on the Add Employee button. You may be able to use the
            batch functionality if you have many employees and meet the
            criteria. Do not use the batch function if you do not meet the
            criteria. This app will not stop you. You will end up with incorrect
            results
          </li>
          <li>
            The final results that should be filled out in your application is
            displayed in the results section.
          </li>
        </ol>

        <H3>Tips and Tricks</H3>
        <ul>
          <li>
            For each separate claim period, you can create a new entity (or
            duplicate an existing one)
          </li>
          <li>
            Create as many entities as you want. Including creating draft ones.
          </li>
          <li>
            Feel free to use nicknames/other descriptive wording for entity and
            employee names. You are the only one that will see this
          </li>
        </ul>
      </div>

      <div className={Classes.DRAWER_FOOTER}>
        <Button fill onClick={handleClose}>
          Close
        </Button>
      </div>
    </Drawer>
  );
}

export default Help;
