import React from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import produce from "immer";
import Big from "big.js";
//imports
import { setEmployee } from "./appStore";
//ui
import {
  Classes,
  Drawer,
  FormGroup,
  InputGroup,
  EditableText,
  Tooltip,
  H3,
  HTMLSelect,
  Callout,
  H5,
  Card,
  HTMLTable,
  Button,
  ButtonGroup,
  Alert,
  Intent,
  Switch,
  Icon,
  H6,
  NumericInput,
  Position,
  Collapse,
  Colors,
} from "@blueprintjs/core";
import { DateRangeInput } from "@blueprintjs/datetime";

var regDec = new RegExp(/^(\d+)?(\.)?\d+$/);
var regAllowable = new RegExp(/^(\d+)?(\.)?(\d+)?$/);
var regInt = new RegExp(/^\d+$/);
var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "CAD",
});

const initialState = {
  name: "",
  isBatch: false,
  batchCount: 1,
  isRelated: false,
  //this is the calculation mode:
  // total - total wage -> prorate by number of weeks
  // custom - weekly -> enter the pay for each week
  mode: "custom",
  //used only for "total" mode
  total: "0", //avg wage => total / (75 days / 7)
  //used only for custom blocks
  custom: [
    /**
     * {
     *  range: [start, end],
     *  start: {y, m, d},
     *  end: {y, m, d},
     *  amount: 1234,
     *  invalidStart: false,
     *  invalidEnd: false,
     * }
     */
  ],
  baseline: "0",
  //total amount paid during the qualifying period
  paid: ["0", "0", "0", "0"],
  subsidy: ["0", "0", "0", "0"],
  totalPaid: "0.00",
  totalSubsidy: "0.00",
  hasError: false,
};

const reducer = produce((draft, action) => {
  switch (action.type) {
    case "reload":
      Object.entries(action.emp).forEach((e) => {
        draft[e[0]] = e[1];
      });
      break;
    case "add/custom/row":
      draft["custom"].push({
        start: { y: 2020, m: 1, d: 1 },
        end: { y: 2020, m: 3, d: 15 },
        amount: 0,
        error: "",
      });
      break;
    case "set/name":
      draft["name"] = action.value;
      break;
    case "set/isRelated":
      draft["isRelated"] = action.value;
      break;
    case "set/mode":
      draft["mode"] = action.value;
      break;
    case "set/custom/range":
      //validate start and end, disregard if not valid
      if (validateDateRange(action.start, action.end) !== true) {
        break;
      }
      draft["custom"][action.row].start = action.start;
      draft["custom"][action.row].end = action.end;
      break;
    case "set/custom/amount":
      draft["custom"][action.row].amount = action.value;
      break;
    case "del/custom/row":
      draft["custom"].splice(action.row, 1);
      break;
    case "set/total":
      draft["total"] = action.value;
      break;
    case "set/paid/value":
      draft["paid"][action.row] = action.value;
      break;
    case "set/batch/totalpaid":
      draft["totalPaid"] = action.value;
      break;
    case "set/batch/count":
      if (action.value > 0) {
        draft["batchCount"] = action.value;
      }
      break;
    default:
      break;
  }
  //DEFAULTS BEFORE CALCULATION
  draft["hasError"] = false;
  //BATCH MODE CALCULATION
  if (draft["isBatch"]) {
    //check if total paid is valid
    if (!regDec.test(draft["totalPaid"])) {
      draft["hasError"] = true;
      draft["totalSubsidy"] = "n/a";
      return;
    }

    //check to make sure batch count is an integer
    if (!regInt.test(draft["batchCount"])) {
      draft["hasError"] = true;
      draft["totalSubsidy"] = "n/a";
      return;
    }

    var cap = Big(847).mul(Big(4)).mul(Big(draft["batchCount"]));

    //check if this is a related party calculation
    if (draft["isRelated"]) {
      //related party is capped at 847/week. We assume their baseline > 1129.33
      draft["totalSubsidy"] = cap.toFixed(2);
      return;
    }

    //for unrelated party, it's the min of 75% of total paid or 847/week
    var temp = Big(draft["totalPaid"]).mul(Big(0.75));
    if (temp.gt(cap)) {
      draft["totalSubsidy"] = cap.toFixed(2);
      return;
    }

    draft["totalSubsidy"] = temp.toFixed(2);

    return;
  }

  //NON BATCH MODE CALCULATION
  //assume no error prior to calculations

  draft["totalPaid"] = "n/a";
  draft["totalSubsidy"] = "n/a";
  draft["subsidy"] = ["0", "0", "0", "0"];

  //calculate baseline pay
  if (draft["mode"] === "total") {
    //check if total is valid
    if (!regDec.test(draft["total"])) {
      draft["hasError"] = true;
      draft["baseline"] = "invalid amount(s)";
      return;
    }

    draft["baseline"] = Big(draft["total"])
      .div(Big(75).div(Big(7)))
      .toFixed(2);
    console.log(draft["baseline"]);
  } else if (draft["mode"] === "custom") {
    //check if custom has data
    if (draft["custom"].length < 1) {
      draft["baseline"] = "No data!";
      draft["hasError"] = true;
      return;
    }

    //check for overlaps
    var overlap = overlapCheck(draft["custom"]);
    if (overlap.size > 0) {
      draft["baseline"] = "Date error!";
      draft["hasError"] = true;
      return;
    }

    draft["baseline"] = calcCustomBaseline(draft["custom"]);
  }

  //check if baseline is valid
  if (!regDec.test(draft["baseline"])) {
    draft["hasError"] = true;
    return;
  }

  //check if paid is all correct
  var paidCheck = draft["paid"].every((x) => {
    return regDec.test(x);
  });

  if (!paidCheck) {
    draft["hasError"] = true;
    return;
  }

  //CALCULATE SUBSIDY

  draft["subsidy"] = calcSubsidy(
    draft["paid"],
    draft["baseline"],
    draft["isRelated"]
  );

  //add up total paid and total subsidy
  draft["totalPaid"] = draft["paid"]
    .reduce((a, b) => {
      return a.plus(Big(b));
    }, Big(0))
    .toFixed(2);
  draft["totalSubsidy"] = draft["subsidy"]
    .reduce((a, b) => {
      return a.plus(Big(b));
    }, Big(0))
    .toFixed(2);
});

function calcSubsidy(paid, baseline, isRelated) {
  var subsidy = ["0", "0", "0", "0"];
  //A - B - C + D
  for (var i = 0; i < paid.length; i++) {
    var v = paid[i];
    //check if v is a number - THIS IS REDUNDANT
    if (!regDec.test(v)) {
      return ["0", "0", "0", "0"];
    }
    //least of A(a)
    var Aa = Big(v).mul(Big(0.75)); //A(a)(i)
    if (Aa.gt(Big(847))) {
      Aa = Big(847);
    }
    if (isRelated) {
      Aa = Big(0);
    }
    //least of A(b)
    var Ab = Big(v);
    var Abii = Big(baseline).mul(Big(0.75));
    if (Ab.gt(Abii)) {
      Ab = Big(baseline).mul(Big(0.75));
    }
    if (Ab.gt(Big(847))) {
      Ab = Big(847);
    }

    if (Ab.gt(Aa)) {
      subsidy[i] = Ab.toFixed(2);
    } else {
      subsidy[i] = Aa.toFixed(2);
    }
  }

  return subsidy;
}

function validateDateRange(start, end) {
  //make sure none of the dates are out of range

  if (start.y !== 2020 || end.y !== 2020) {
    return "date out of range";
  }
  if (start.m < 1 || start.m > 3 || end.m < 1 || end.m > 3) {
    return "date out of range";
  }
  if (start.m === 3 && start.d > 15) {
    return "date out of range";
  }
  if (end.m === 3 && end.d > 15) {
    return "date out of range";
  }
  if (start.d < 1 || end.d < 1) {
    return "invalid dates";
  }
  //make sure start <= end
  if (start.m > end.m) {
    return "invalid range";
  }
  if (start.m === end.m && start.d > end.d) {
    return "invalid range";
  }
  //if all ok
  return true;
}

//returns an array of all the rows that have overlaps
function overlapCheck(c) {
  //array to keep track of the periods we're paid
  var cal = [...Array(75)].map((x) => []);
  //go through our ranges and fill in our calendar
  for (var i = 0; i < c.length; i++) {
    //if any of the range is before Jan 1 or after Mar 15, abort the
    //entire calculation
    var start = c[i].start;
    var end = c[i].end;

    //find starting position
    var startPos = start.d - 1 + (start.m === 2) * 31 + (start.m === 3) * 60;
    var endPos = end.d - 1 + (end.m === 2) * 31 + (end.m === 3) * 60;

    for (var j = 0; j <= endPos - startPos; j++) {
      //make sure there's no overlaps
      cal[startPos + j].push(i);
    }
  }
  let result = new Set();
  //check if anything in cal has array > 1
  for (i = 0; i < cal.length; i++) {
    if (cal[i].length > 1) {
      cal[i].forEach((x) => result.add(x));
    }
  }
  return result;
}

function calcCustomBaseline(c) {
  //array to keep track of the periods we're paid
  var cal = Array(75).fill(0);
  var total = Big(0);
  //go through our ranges and fill in our calendar
  for (var i = 0; i < c.length; i++) {
    //if any of the range is before Jan 1 or after Mar 15, abort the
    //entire calculation
    var start = c[i].start;
    var end = c[i].end;

    //find starting position
    var startPos = start.d - 1 + (start.m === 2) * 31 + (start.m === 3) * 60;
    var endPos = end.d - 1 + (end.m === 2) * 31 + (end.m === 3) * 60;

    for (var j = 0; j <= endPos - startPos; j++) {
      cal[j + startPos] = 1;
    }
    //validate amount; if invalid stop the calculation
    if (regDec.test(c[i].amount)) {
      total = total.add(Big(c[i].amount));
    } else {
      return "invalid amount(s)";
    }
  }
  console.log(cal);
  //check calendar for 7 consecutive non working days
  var last = 0;
  var nonworked = 0;
  for (i = 0; i < cal.length; i++) {
    if (cal[i] === 0) {
      last++;
      console.log(i + 1, last);
    } else {
      //if last > 7, add to nonworked, else reset to 0
      if (last >= 7) {
        nonworked += last;
      }
      last = 0;
    }
  }
  //check last cell
  if (last >= 7) {
    nonworked += last;
  }
  console.log("total non worked: ", nonworked);
  //total days is 75 - nonworked
  var days = 75 - nonworked;
  return total.div(Big(days).div(Big(7))).toFixed(2);
}

function periodString(period) {
  switch (period) {
    case 3:
      return [
        "March 15 to March 21, 2020",
        "March 22 to March 28, 2020",
        "March 29 to April 4, 2020",
        "April 5 to April 11, 2020",
      ];
    case 4:
      return [
        "April 12 to April 18, 2020",
        "April 19 to April 25, 2020",
        "April 26 to May 2, 2020",
        "May 3 to May 9, 2020",
      ];
    case 5:
      return [
        "May 10 to May 16, 2020",
        "May 17 to May 23, 2020",
        "May 24 to May 30, 2020",
        "May 31 to June 6, 2020",
      ];
    default:
      return [];
  }
}

function EditBatch({ props, period, dispatch }) {
  /**
   * Batch mode should only be used if one of the following is true for the batch of employees:
          All members of this batch are arm length parties (not related) and have earned $1,129.33 or
          more for each week of the claim period

          All members of this batch are arm length parties and have earned less than $1,129.33 for each
          week of the claim period

          All members of this batch are non-arm length parties (related) and have earned $1,129.33 or more
          for each week of the claim period AND have earned an average of $1,129.33 per week or more between
          January 1, 2020 and March 15, 2020
    
          ^ above options 1, 2, 3
   */
  const [batchMode, setBatchMode] = React.useState("1");
  //do a sanity check on total paid
  var warning = "";
  if (regDec.test(props.totalPaid)) {
    var threshold = Big(1129.33).mul(Big(4)).mul(Big(props.batchCount));
    if (batchMode === "1") {
      //totalpaid should be >= 1,129.33 * 4 * count
      if (Big(props.totalPaid).lt(threshold)) {
        warning =
          "Expecting every member of this batch to have earned $1,129.33 or more per week. Please double check your calculations";
      }
    } else if (batchMode === "2") {
      //totalpaid should be < 1,129.33 * 4 * count
      if (Big(props.totalPaid).lt(threshold)) {
        warning =
          "Expecting every member of this batch to have earned less than $1,129.33 per week. Please double check your calculations";
      }
    } else if (batchMode === "3") {
      //totalpaid should be >= 1,129.33 * 4 * count
      if (Big(props.totalPaid).lt(threshold)) {
        warning =
          "Expecting every member of this batch to have earned $1,129.33 or more per week. Please double check your calculations";
      }
    }
  }
  return (
    <div className={Classes.DRAWER_BODY}>
      <div className={Classes.DIALOG_BODY}>
        <Callout intent="warning">
          <p>
            Make sure you meet the criteria for using batch mode. If you do not
            meet these criteria then this will result in an incorrect
            calculation.
            <b>One of the following must be true</b>:
          </p>
          <ul>
            <li>
              All members of this batch are arm length parties (not related) and
              have earned <b>$1,129.33 or more</b> for each week of the claim
              period
            </li>
            <li>
              All members of this batch are arm length parties and have earned{" "}
              <b>less than $1,129.33</b> for each week of the claim period{" "}
              <b>AND</b> there has been no reduction in pay
            </li>
            <li>
              All members of this batch are <b>non-arm length parties</b>{" "}
              (related) and have earned <b>$1,129.33 or more</b> for each week
              of the claim period <b>AND</b> have earned an average of $1,129.33
              per week or more between January 1, 2020 and March 15, 2020
            </li>
          </ul>
        </Callout>
        <br />
        <Callout intent="warning">
          <p>
            <b>
              Do not include any employees that have already been accounted for
              individually.
            </b>{" "}
            Doing so would result in double counting.
          </p>
        </Callout>
        <br />
        <Card style={{ marginBottom: "1em" }}>
          <H5>Batch information</H5>
          <FormGroup
            helperText="Cannot be less than 1"
            label="Number of employee in this batch"
            labelFor="text-input"
          >
            <NumericInput
              min={1}
              minorStepSize={1}
              onValueChange={(v) => {
                dispatch({
                  type: "set/batch/count",
                  value: v,
                });
              }}
              intent={regInt.test(props.batchCount) ? "success" : "danger"}
              value={props.batchCount}
            />
          </FormGroup>
          <HTMLSelect
            id="select-batch-type"
            fill
            options={[
              {
                label: "Non-related party batch earning >= $1,129.33/week",
                value: "1",
              },
              {
                label: "Non-related party batch earning < $1,129.33/week",
                value: "2",
              },
              { label: "Related party batch", value: "3" },
            ]}
            value={batchMode}
            onChange={(e) => {
              dispatch({
                type: "set/isRelated",
                value: e.currentTarget.value === "3",
              });
              setBatchMode(e.currentTarget.value);
            }}
          />
          {batchMode === "3" ? (
            <Callout intent="primary" style={{ marginTop: "10px" }}>
              <p>
                You have indicated that this is a batch of non-arm length
                employees (i.e. related party). You{" "}
                <b>must ensure ALL of the following are true</b>:
              </p>
              <ul>
                <li>
                  All members of this batch have earned $1,129.33 or more for
                  each week of the claim period
                </li>
                <li>
                  All members of this batch have earned $1,129.33 or more per
                  week on average for the period between January 1, 2020 and
                  March 15, 2020 (inclusive)
                </li>
              </ul>
            </Callout>
          ) : null}
        </Card>
        <Card style={{ marginBottom: "1em" }}>
          <H5>Calculate subsidy</H5>
          <FormGroup
            helperText="This should be the sum of all wages paid to this batch of employee during the select claim period"
            label="Enter total wages"
            labelFor="text-input"
          >
            <InputGroup
              placeholder="Amount"
              value={props.totalPaid}
              intent={regDec.test(props.totalPaid) ? "success" : "danger"}
              onChange={(e) => {
                //if this is an allowable string
                if (regAllowable.test(e.target.value)) {
                  dispatch({
                    type: "set/batch/totalpaid",
                    value: e.target.value,
                  });
                  return;
                }
              }}
            />
          </FormGroup>
          <br />
          {warning !== "" ? (
            <div>
              <Callout intent="warning" title="Warning">
                {warning}
              </Callout>
              <br />
            </div>
          ) : null}
          <Callout>
            <H6>
              Total subsidy for the claim period:{" "}
              {formatter.format(props.totalSubsidy).replace("CA$", "$")}
            </H6>
          </Callout>
        </Card>
      </div>
    </div>
  );
}

function EditSingleEmployee({ props, period, dispatch }) {
  const [isOpen, setIsOpen] = React.useState(["info", "baseline", "pay"]);
  const handleToggle = (id) => {
    //if id already exists remove
    var r = [];
    if (isOpen.includes(id)) {
      r = isOpen.filter((e) => {
        return e !== id;
      });
    }
    //else add
    else {
      r = isOpen.slice();
      r.push(id);
    }
    setIsOpen(r);
  };

  //custom baseline rows
  var overlap = overlapCheck(props.custom);
  const customBaselineRows = props.custom.map((e, i) => {
    var hasOverlap = overlap.has(i);
    var invalidAmount = !regDec.test(e.amount);
    var error = hasOverlap || invalidAmount;
    var errorBox = (
      <Tooltip
        content={
          (hasOverlap ? "Date range overlaps! " : "") +
          (invalidAmount ? "Amount invalid!" : "")
        }
        intent="danger"
      >
        <Icon icon="warning-sign" intent="danger" />
      </Tooltip>
    );
    return (
      <tr key={i}>
        <td>{error ? errorBox : null}</td>
        <td>
          <DateRangeInput
            formatDate={(date) => {
              // console.log(date);
              return moment(date).format("YYYY-MM-DD");
            }}
            parseDate={(str) => {
              console.log(str);
              moment(str).toDate();
            }}
            value={[
              new Date(e.start.y, e.start.m - 1, e.start.d),
              new Date(e.end.y, e.end.m - 1, e.end.d),
            ]}
            minDate={new Date(2020, 0, 1)}
            maxDate={new Date(2020, 2, 15)}
            allowSingleDayRange={true}
            onChange={(range) => {
              /**Called when the user selects a day. If no days are selected,
               * it will pass [null, null]. If a start date is selected but not an end date,
               * it will pass [selectedDate, null]. If both a start and end date are selected,
               * it will pass [startDate, endDate]. */
              if (range[0] === null || range[1] === null) return;

              console.log(range[0]);
              console.log(range[1]);

              //THERE'S A CASE HERE WHERE IF YOU PRESS ENTER ONE OF THE DATES WILL BE UNDEFINED
              //AND EXECUTION CRASHES HERE??
              if (range[0] === undefined || range[1] === undefined) return;

              var start = {
                y: range[0].getFullYear(),
                m: range[0].getMonth() + 1,
                d: range[0].getDate(),
              };
              var end = {
                y: range[1].getFullYear(),
                m: range[1].getMonth() + 1,
                d: range[1].getDate(),
              };

              dispatch({
                type: "set/custom/range",
                row: i,
                start: start,
                end: end,
              });
            }}
          />
        </td>
        <td>
          <InputGroup
            placeholder="Amount"
            value={e.amount}
            intent={regDec.test(e.amount) ? "success" : "danger"}
            onChange={(e) => {
              //if this is an allowable string
              if (regAllowable.test(e.target.value)) {
                dispatch({
                  type: "set/custom/amount",
                  row: i,
                  value: e.target.value,
                });
                return;
              }
            }}
          />
        </td>
        <td>
          <Button
            minimal
            small
            icon="delete"
            intent="danger"
            onClick={() => dispatch({ type: "del/custom/row", row: i })}
          />
        </td>
      </tr>
    );
  });

  var periodLabels = periodString(period);
  const paidRows = props.paid.map((e, i) => {
    return (
      <tr key={i}>
        <td>{i + 1}</td>
        <td>{periodLabels[i]}</td>
        <td>
          <InputGroup
            placeholder="Amount"
            value={e}
            intent={regDec.test(e) ? "success" : "danger"}
            onChange={(e) => {
              //if this is an allowable string
              if (regAllowable.test(e.target.value)) {
                //handleSetValue("total", e.target.value);
                dispatch({
                  type: "set/paid/value",
                  row: i,
                  value: e.target.value,
                });
                return;
              }
            }}
          />
        </td>
        <td>{props.subsidy[i]}</td>
      </tr>
    );
  });

  return (
    <div className={Classes.DRAWER_BODY}>
      <div className={Classes.DIALOG_BODY}>
        <H5>
          <span style={{ color: Colors.BLUE1 }}>Employee Information</span>{" "}
          <Button
            minimal
            onClick={() => handleToggle("info")}
            className="no-print"
            icon={isOpen.includes("info") ? "chevron-down" : "chevron-left"}
          />
        </H5>
        <Collapse isOpen={isOpen.includes("info")}>
          <Card style={{ marginTop: "1em", marginBottom: "1em" }}>
            <Switch
              labelElement={
                "Is this employee a non arm-length party (i.e. related)"
              }
              innerLabelChecked="yes"
              innerLabel="no"
              checked={props.isRelated}
              onChange={(e) =>
                dispatch({
                  type: "set/isRelated",
                  value: e.currentTarget.checked,
                })
              }
            />
          </Card>
        </Collapse>
        <H5>
          <span style={{ color: Colors.BLUE1 }}>
            Step 1: Calculate Baseline Remuneration
          </span>{" "}
          <Button
            minimal
            onClick={() => handleToggle("baseline")}
            className="no-print"
            icon={isOpen.includes("baseline") ? "chevron-down" : "chevron-left"}
          />
        </H5>
        <Collapse isOpen={isOpen.includes("baseline")}>
          <Card style={{ marginTop: "1em", marginBottom: "1em" }}>
            <Callout intent="primary" title="Info">
              <p>
                In order to calculate the baseline renumeration, you must choose
                one of the following two options
              </p>
              <ul>
                <li>
                  <b>Total wages:</b> Use this if this employee worked regular
                  working days between January 1, 2020 to March 15, 2020. Do not
                  use this mode if there are any periods of 7 or more
                  consecutive days for which this employee was not remunerated.
                </li>
                <li>
                  <b>Custom periods:</b> Use this for all other scenarios
                </li>
              </ul>
            </Callout>
            <br />
            <FormGroup
              helperText="Select the calculation mode to be used for this employee."
              labelFor="select-period"
            >
              <HTMLSelect
                id="select-period"
                fill
                options={[
                  { label: "Total wages paid", value: "total" },
                  { label: "Custom periods", value: "custom" },
                ]}
                value={props.mode}
                onChange={(e) => {
                  dispatch({ type: "set/mode", value: e.currentTarget.value });
                }}
              />
            </FormGroup>

            {props.mode === "total" ? (
              <FormGroup
                helperText="Enter total wages paid between January 1, 2020 and March 15, 2020 (inclusive)"
                label="Total wage paid Jan 1 to Mar 15, 2020"
                labelFor="text-input"
              >
                <InputGroup
                  placeholder="Amount"
                  value={props.total}
                  intent={regDec.test(props.total) ? "success" : "danger"}
                  onChange={(e) => {
                    //if this is an allowable string
                    if (regAllowable.test(e.target.value)) {
                      dispatch({ type: "set/total", value: e.target.value });
                      return;
                    }
                  }}
                />
              </FormGroup>
            ) : null}
            {props.mode === "custom" ? (
              <div>
                <Callout intent="primary" title="Instructions">
                  <p>
                    Start by adding new rows for each period this employee was
                    paid for
                  </p>
                  <p>
                    The periods <b>must not overlap</b>. For example, if the
                    first row is for the period from January 6 to January 8,
                    there cannot be another row for the period from January 7 to
                    January 10.
                  </p>
                  <p>
                    Enter only periods for which the employee is paid. For
                    example, if the employee worked 3 days between January 6 to
                    8 but was off January 9 to January 10, the date range should
                    be January 6 to 8, <b>NOT</b> January 6 to 10. This is
                    critical in order for the app to correctly calculate if
                    there are any 7 consecutive days for which this employee was
                    not paid.
                  </p>
                </Callout>
                <HTMLTable condensed striped style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <td></td>
                      <td>Date range</td>
                      <td>Amount paid</td>
                    </tr>
                  </thead>
                  <tbody>
                    {customBaselineRows.length > 0 ? (
                      customBaselineRows
                    ) : (
                      <tr>
                        <td colSpan={3}>
                          No rows. Click add row button to start
                        </td>
                      </tr>
                    )}
                  </tbody>
                </HTMLTable>
                <br />
                <Button
                  fill
                  icon="add"
                  onClick={() => dispatch({ type: "add/custom/row" })}
                >
                  Add row
                </Button>
                <br />
              </div>
            ) : null}

            <Callout>
              <H6>Baseline pay/week: {props.baseline}</H6>
            </Callout>
          </Card>
        </Collapse>
        <H5>
          <span style={{ color: Colors.BLUE1 }}>
            Step 2: Remuneration Paid During Qualifying Period
          </span>{" "}
          <Button
            minimal
            onClick={() => handleToggle("pay")}
            className="no-print"
            icon={isOpen.includes("pay") ? "chevron-down" : "chevron-left"}
          />
        </H5>
        <Collapse isOpen={isOpen.includes("pay")}>
          <Card style={{ marginTop: "1em", marginBottom: "1em" }}>
            <HTMLTable condensed striped style={{ width: "100%" }}>
              <thead>
                <tr>
                  <td>Week #</td>
                  <td>Dates</td>
                  <td>Amount paid</td>
                  <td>Subsidy</td>
                </tr>
              </thead>
              <tbody>{paidRows}</tbody>
            </HTMLTable>
          </Card>
        </Collapse>
      </div>
    </div>
  );
}

function EditEmployee({ entityID, employeeID, isOpen, handleClose }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { employee, period } = useSelector((state) => ({
    employee: state.app.entity[entityID].employee[employeeID],
    period: state.app.entity[entityID].period,
  }));
  const reduxDispatch = useDispatch();
  //make a copy of all employee states to a local state
  const [name, setName] = React.useState("");
  const [saved, setSaved] = React.useState(true);
  const [openConfirm, setOpenConfirm] = React.useState(false);

  //refresh when entity or employee changes
  React.useEffect(() => {
    console.log("reloading");
    dispatch({ type: "reload", emp: employee });
    setName(employee.name);
    setSaved(true);
    setOpenConfirm(false);
  }, [entityID, employeeID, employee, isOpen]);

  const dispatchWrapper = (v) => {
    setSaved(false);
    dispatch(v);
  };

  const handleSaveEdit = () => {
    reduxDispatch(
      setEmployee({
        id: entityID,
        emp: employeeID,
        value: state,
      })
    );
    setSaved(true);
  };

  const checkSavedBeforeClose = () => {
    if (!saved) {
      setOpenConfirm(true);
    } else {
      handleClose();
    }
  };

  return (
    <Drawer
      icon="edit"
      onClose={checkSavedBeforeClose}
      isOpen={isOpen}
      position={Position.LEFT}
      title={
        <Tooltip content="Click on name to edit">
          <H3>
            <EditableText
              value={name}
              onChange={(v) => setName(v)}
              onCancel={(v) => setName(v)}
              onConfirm={() =>
                dispatchWrapper({ type: "set/name", value: name })
              }
            />
          </H3>
        </Tooltip>
      }
      size={Drawer.SIZE_LARGE}
    >
      {state.isBatch ? (
        <EditBatch props={state} period={period} dispatch={dispatchWrapper} />
      ) : (
        <EditSingleEmployee
          props={state}
          period={period}
          dispatch={dispatchWrapper}
        />
      )}
      <div className={Classes.DRAWER_FOOTER}>
        {state.hasError ? (
          <Callout intent="danger">
            There is currently an error with this employee. Please check inputs.
            Subsidy will not be calculated
          </Callout>
        ) : null}
        <ButtonGroup fill>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleSaveEdit} disabled={saved} intent="primary">
            Save
          </Button>
        </ButtonGroup>
      </div>
      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Continue without saving"
        icon="warning-sign"
        intent={Intent.DANGER}
        isOpen={openConfirm}
        onCancel={() => setOpenConfirm(false)}
        onConfirm={() => {
          setOpenConfirm(false);
          setSaved(true);
          handleClose();
        }}
      >
        <p>
          You have unsaved changes. Are you sure you want to close this page?
        </p>
      </Alert>
    </Drawer>
  );
}

export default EditEmployee;
